/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { MdArrowDownward as ArrowDownwardIcon } from "react-icons/md"

import Layout from "../../layouts"
import Header from "../../components/header"
import GuidelinesLayout from "../../components/guidelines/layout"
import BoxWithBorder from "../../components/guidelines/box-with-border"
import {
  Intro,
  PageHeading,
  SectionHeading,
} from "../../components/guidelines/typography"
import {
  Container,
  Section,
  Columns,
  ContentColumn,
  CopyColumn,
} from "../../components/guidelines/containers"

import GatsbyLogo from "../../components/guidelines/logo"
import GatsbyMonogram from "../../components/guidelines/logo/monogram"
import {
  Wordmark,
  Clearspace,
  ClearspaceMonogram,
  ManuallyTracked,
  PartnershipLockups,
} from "../../assets/guidelines"

import ColorSwatch from "../../components/guidelines/color/card"

import { Text } from "../../components/guidelines/system"
import { Box, Flex } from "theme-ui"
import palette from "../../utils/guidelines/extend-palette-info"
import { AnchorButton } from "gatsby-interface"

const List = ({ children }) => (
  <ul
    sx={{
      ml: 0,
      p: 0,
      listStyle: `none`,
    }}
  >
    {children}
  </ul>
)

const listItemStyle = {
  backgroundPosition: `0 0.25em`,
  backgroundRepeat: `no-repeat`,
  backgroundSize: `1em`,
  pl: `1.5em`,
  mb: `1em`,
}

const ListItem = ({ children }) => (
  <li
    sx={theme => ({
      ...listItemStyle,
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='${encodeURIComponent(
        theme.colors.green[`50`]
      )}' d='M23,12L20.56,9.22L20.9,5.54L17.29,4.72L15.4,1.54L12,3L8.6,1.54L6.71,4.72L3.1,5.53L3.44,9.21L1,12L3.44,14.78L3.1,18.47L6.71,19.29L8.6,22.47L12,21L15.4,22.46L17.29,19.28L20.9,18.46L20.56,14.78L23,12M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9L10,17Z' /%3E%3C/svg%3E")`,
    })}
  >
    {children}
  </li>
)

const DontListItem = ({ children }) => (
  <li
    sx={theme => ({
      ...listItemStyle,
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='${encodeURIComponent(
        theme.colors.red[`50`]
      )}' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E")`,
    })}
  >
    {children}
  </li>
)

const Guidance = ({ children, image }) => (
  <div
    sx={theme => ({
      mb: 6,
      mr: 6,
      width: `calc(50% - ${theme.space[6]})`,
    })}
  >
    {image && (
      <BoxWithBorder withBorder>
        <Img fluid={image.childImageSharp.fluid} />
      </BoxWithBorder>
    )}
    <Text fontSize={1} color="grey.50" mt={3}>
      {children}
    </Text>
  </div>
)

const Monogram = ({ size, isLast }) => (
  <Box
    sx={theme => ({
      display: isLast ? `none` : `flex`,
      flexShrink: 0,
      alignItems: `center`,
      flexDirection: `column`,
      mt: [0, null, null, 7],
      mr: [5, null, null, 7],
      [theme.mediaQueries.tablet]: {
        display: isLast ? `block` : `flex`,
      },
    })}
  >
    <Flex
      sx={{
        width: size,
        mb: 5,
        svg: {
          display: `block`,
          height: `100%`,
          width: `100%`,
        },
      }}
    >
      <GatsbyMonogram />
    </Flex>
    <Text color="grey.50" fontSize={1}>
      {size} x {size}px
    </Text>
  </Box>
)

const GatsbyLogoContainered = ({
  height,
  opacity,
  inverted,
  calm,
  ...rest
}) => (
  <Box
    {...rest}
    sx={{
      height: height,
      maxWidth: `400px`,
      opacity: opacity || 1,
      svg: {
        display: `block`,
        width: height ? `auto` : `100%`,
        height: height ? height : false,
      },
    }}
  >
    <GatsbyLogo inverted={inverted} calm={calm} />
  </Box>
)

const LogoContainer = ({ bg, inverted, calm, withBorder, ...rest }) => (
  <BoxWithBorder bg={bg} p={4} pb="56.25%" withBorder={withBorder} {...rest}>
    <Flex
      sx={{
        alignItems: `center`,
        justifyContent: `center`,
        position: `absolute`,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: `100%`,
        width: `100%`,
      }}
    >
      <Box
        sx={theme => ({
          height: `40px`,
          [theme.mediaQueries.desktop]: {
            height: `48px`,
          },
          svg: {
            display: `block`,
            height: `100%`,
          },
        })}
      >
        <GatsbyLogo inverted={inverted} calm={calm} />
      </Box>
    </Flex>
  </BoxWithBorder>
)

const Logo = ({ data, location }) => {
  return (
    <Layout>
      <Header isFullWidth={true} />
      <GuidelinesLayout location={location} pageTitle="Logo">
        <Container>
          <PageHeading>Logo</PageHeading>
          <Intro>
            The Gatsby logo is the central visual cue to identify Gatsby and its
            official resources, publications, community projects, products, and
            integrations. Everywhere the Gatsby logo shows up, it should act and
            behave the same.
          </Intro>
        </Container>

        <Section>
          <Columns>
            <CopyColumn>
              <p>
                Because Gatsby <em>is and always will be free</em> open source
                software, but also is backed by Gatsby the company, this guide
                is relevant not only for internal use, but also for our
                community members and commercial partners.
              </p>
              <p>
                Whether you want to reference Gatsby in your publication or
                service, show some love or link back to us, we hope this page
                contains everything you need.
              </p>
              <Box
                sx={{
                  py: 4,
                  px: 5,
                  my: 5,
                  fontSize: 1,
                  borderRadius: 2,
                  maxWidth: `30rem`,
                  bg: `yellow.10`,
                  color: `grey.80`,
                }}
              >
                Please{` `}
                <a href="https://github.com/gatsbyjs/gatsby/issues">
                  open an issue
                </a>
                {` `}
                on GitHub or{` `}
                <a href="mailto:team@gatsbyjs.com">send a mail</a> to the Gatsby
                Inkteam if you have any questions, suggestions, or problems!
                {` `}
                <strong>Happy shipping!</strong>
              </Box>
            </CopyColumn>
            <ContentColumn>
              <GatsbyLogoContainered mb={5} />
              <p>
                Gatsby’s logo was created by Sacha Greif in late 2016, and is a
                true open source community effort.{` `}
                <span role="img" aria-label="purple heart">
                  💜
                </span>
                <span role="img" aria-label="thank you">
                  🙏
                </span>
                {` `}
                Check out <a href="#footnotes">the footnotes</a> to retrace the
                most important iteration steps.
              </p>
              <AnchorButton
                size="M"
                href="/Gatsby-Logos.zip"
                sx={{ mb: 2, "&&": { color: `white` } }}
                rightIcon={<ArrowDownwardIcon />}
              >
                Download all logo assets
              </AnchorButton>
              <Text as="p" fontSize={1} color="grey.50">
                Contains EPS, PNG, and SVG files
                <br />
                v1.0, May 28, 2019
              </Text>
              <Text as="p" mt={3} mb={0} fontSize={1}>
                In a hurry? Here’s just the SVGs:
              </Text>
              <AnchorButton
                size="M"
                href="/Gatsby-Logo.svg"
                sx={{ mt: 4, mb: 4, mr: 4, "&&": { color: `white` } }}
                rightIcon={<ArrowDownwardIcon />}
              >
                Logo SVG
              </AnchorButton>
              <AnchorButton
                size="M"
                href="/Gatsby-Monogram.svg"
                sx={{ mt: 3, mb: 3, "&&": { color: `white` } }}
                rightIcon={<ArrowDownwardIcon />}
              >
                Monogram SVG
              </AnchorButton>
            </ContentColumn>
          </Columns>
        </Section>

        <Section id="dos-and-donts">
          <Columns>
            <CopyColumn sticky={false} id="dos">
              <SectionHeading>Do these awesome things</SectionHeading>
              <List>
                <ListItem>
                  Use the Gatsby logo or monogram to link to gatsbyjs.org or
                  gatsbyjs.com
                </ListItem>
                <ListItem>
                  Use the Gatsby logo or monogram to advertise that your product
                  has built-in Gatsby integration
                </ListItem>
                <ListItem>
                  Use the Gatsby logo or monogram in a blog post or news article
                  about Gatsby
                </ListItem>
                <ListItem>
                  Use the Gatsby monogram when linking to your Gatsby community
                  profile
                </ListItem>
              </List>
            </CopyColumn>
            <ContentColumn>
              <SectionHeading id="donts">
                Please don’t do these things
              </SectionHeading>
              <List>
                <DontListItem>
                  Use the Gatsby logo or monogram for your application’s icon
                </DontListItem>
                <DontListItem>
                  Create a modified version of the Gatsby logo or monogram,
                  change the colors, dimensions or add your own text/images —
                  please see the <a href="#guidance">Guidance</a> section below
                  for examples
                </DontListItem>
                <DontListItem>
                  Integrate the Gatsby logo or monogram into your logo
                </DontListItem>
                <DontListItem>
                  Sell any Gatsby artwork without permission
                </DontListItem>
              </List>
            </ContentColumn>
          </Columns>
        </Section>

        <Section id="primary-logo">
          <SectionHeading>Primary Logo</SectionHeading>
          <Columns>
            <CopyColumn sticky={false}>
              <p>
                The primary Gatsby logo is a combination mark that consists of
                the Gatsby monogram/lettermark, and a wordmark. It is the
                preferred way to reference Gatsby, allowing newcomers to
                associate our brand name with the monogram. We encourage you to
                use it whenever possible.
              </p>
            </CopyColumn>
            <ContentColumn>
              <GatsbyLogoContainered />
            </ContentColumn>
          </Columns>
        </Section>

        <Section id="monogram">
          <SectionHeading>Monogram</SectionHeading>
          <Columns>
            <CopyColumn sticky={false}>
              <p>
                We use the monogram as social media profile image for our
                official Twitter and GitHub accounts, and as “favicon” for our
                official website.
              </p>
              <p>
                Furthermore the monogram may be used in cases where the
                association with Gatsby is evident, especially when space is an
                issue, e.g. like we currently do on store.gatsbyjs.org for
                mobile devices.
              </p>
            </CopyColumn>
            <ContentColumn>
              <Flex
                sx={{
                  alignItems: `flex-end`,
                  flexWrap: `wrap`,
                }}
              >
                <Monogram size={128} />
                <Monogram size={64} />
                <Monogram size={32} />
                <Monogram size={16} isLast />
              </Flex>
            </ContentColumn>
          </Columns>
        </Section>

        <Section id="partnership-lockups">
          <SectionHeading>Partnership Lockups</SectionHeading>
          <Columns>
            <CopyColumn sticky={false}>
              <p>
                When combining our logotype with another brand, product, or
                technology, we prefer the monogram over the logotype. It should
                be the same visual weight as the partner&apos;s logo, and
                connected by a &ldquo;plus&rdquo; sign.
              </p>
            </CopyColumn>
            <ContentColumn>
              <Flex
                sx={{
                  alignItems: `flex-end`,
                  flexWrap: `wrap`,
                }}
              >
                <Box
                  sx={{
                    maxWidth: `542px`,
                    mb: 5,
                    svg: { display: `block`, width: `100%` },
                  }}
                >
                  <PartnershipLockups />
                </Box>
              </Flex>
            </ContentColumn>
          </Columns>
        </Section>

        <Section id="wordmark">
          <SectionHeading>Wordmark</SectionHeading>
          <Columns>
            <CopyColumn sticky={false}>
              <p>
                The typeface used to set the logo wordmark is Futura PT Demi.
                The wordmark is optically kerned, and its uppercase “G” is
                customized to partly mirror the strict geometry of the monogram.
              </p>
              <Text color="grey.40" fontSize={1}>
                Futura PT Demi
              </Text>
              <Text
                fontSize={5}
                fontFamily="heading"
                fontWeight="heading"
                lineHeight="dense"
              >
                ABCDEFGHIJKLMNOPQRSTUVWXYZ
                <br />
                abcdefghijklmnopqrstuvwxyz
                <br />
                1234567890!@€$%&*()-=+
              </Text>
            </CopyColumn>
            <ContentColumn>
              <Box
                sx={{
                  maxWidth: `257px`,
                  mb: 5,
                  svg: { display: `block`, width: `100%` },
                  color: `black`,
                }}
              >
                <Wordmark />
              </Box>
              <Box
                sx={{
                  maxWidth: `257px`,
                  mb: 4,
                  svg: { display: `block`, width: `100%` },
                }}
              >
                <ManuallyTracked />
              </Box>
              <Text as="span" color="blue.70">
                Manual kerning and custom “G”
              </Text>
              {` `}
              vs.{` `}
              <Text as="span" color="red.60">
                Futura PT Demi
              </Text>
            </ContentColumn>
          </Columns>
        </Section>

        <Section id="colors">
          <SectionHeading>Colors</SectionHeading>
          <Columns>
            <CopyColumn>
              <p>
                The Gatsby logo colors are rebeccapurple (
                <Link to="/guidelines/color/">Purple 60</Link>), black, and
                white. The logo works best on a white background.
              </p>
            </CopyColumn>
            <ContentColumn>
              <LogoContainer bg="white" withBorder mb={5} />
              <Box
                sx={theme => ({
                  display: `flex`,
                  flexDirection: `column`,
                  [theme.mediaQueries.tablet]: {
                    flexDirection: `row`,
                  },
                })}
              >
                <ColorSwatch
                  color={palette.purple.colors[`60`]}
                  sx={{
                    mr: [0, null, null, 5],
                    mb: [5, null, null, 0],
                    width: `100%`,
                  }}
                />
                <ColorSwatch
                  color={palette.black.color}
                  sx={{
                    mr: [0, null, null, 5],
                    mb: [5, null, null, 0],
                    width: `100%`,
                  }}
                />
                <ColorSwatch
                  color={palette.white.color}
                  sx={{
                    mr: 0,
                    mb: [5, null, null, 0],
                    width: `100%`,
                  }}
                />
              </Box>
            </ContentColumn>
          </Columns>
          <Columns>
            <CopyColumn>
              <p>
                There are two additional, one-color versions of the Gatsby logo:
                An entirely black or white logo for those instances where the
                logo must display or print in a single color.
              </p>
              <p>For dark backgrounds, the logo should always be white.</p>
              <p>
                There are no absolutes regarding the selection of the specific
                color application, but context, contrast with regard to
                background color, and surrounding imagery and production
                parameters all should be considered.
              </p>
            </CopyColumn>
            <ContentColumn>
              <LogoContainer bg="purple.60" inverted />
            </ContentColumn>
          </Columns>
          <Columns>
            <CopyColumn>
              <p>
                Additionally, we allow the single color version of the Gatsby
                logo to adapt to light colored backgrounds as long as a healthy
                contrast is preserved. Our example uses base neutral (
                <Link to="/guidelines/color/">Grey 60</Link>) on{` `}
                <Link to="/guidelines/color/">Grey 20</Link>.
              </p>
            </CopyColumn>
            <ContentColumn>
              <LogoContainer bg="grey.20" calm />
            </ContentColumn>
          </Columns>
        </Section>

        <Section bg="blue.5" id="clearspace">
          <SectionHeading color="black">Clearspace</SectionHeading>
          <Columns>
            <CopyColumn>
              <p sx={{ color: `grey.90` }}>
                To ensure the legibility of the logo, it must be surrounded with
                a minimum amount of clearspace. This isolates the logo from
                competing elements such as photography, text or background
                patterns that may detract attention and lessen the overall
                impact.
              </p>
              <Text color="grey.90">
                <Text as="span" color="magenta.50">
                  Magenta
                </Text>
                {` `}
                indicates clearspace, defined by the logo wordmark x-height or
                by 1/4 height of the Gatsby monogram.{` `}
                <Text as="span" color="blue.70">
                  Blue
                </Text>
                {` `}
                marks type and element alignment and construction.
              </Text>
            </CopyColumn>
            <ContentColumn>
              <Box
                sx={{
                  mb: 5,
                  svg: { display: `block`, maxWidth: 506 },
                }}
              >
                <Clearspace />
              </Box>
              <Text as="p" mb={8} fontSize={1} color="grey.50">
                <Text as="span" color="magenta.50">
                  Clearspace
                </Text>
                {` `}
                around the logo is equal to the wordmark x-height.
              </Text>
              <Box
                sx={{
                  mb: 5,
                  svg: { display: `block`, maxWidth: 122 },
                }}
              >
                <ClearspaceMonogram />
              </Box>
              <Text as="p" mb={0} fontSize={1} color="grey.50">
                <Text as="span" color="magenta.50">
                  Clearspace
                </Text>
                {` `}
                around the monogram equals 1/4 of its height.
              </Text>
            </ContentColumn>
          </Columns>
          <SectionHeading id="scale">Scale</SectionHeading>
          <Columns>
            <CopyColumn sticky={false}>
              <p sx={{ color: `grey.90` }}>
                Our logo is designed to scale to small sizes on print and
                screen.
              </p>
              <p sx={{ color: `grey.90` }}>
                Smallest size: 24 pixels high for screens, 0.3 inch/0.762
                centimeter high for print.
              </p>
            </CopyColumn>
            <ContentColumn>
              <div
                sx={{
                  position: `relative`,
                }}
              >
                <GatsbyLogoContainered opacity={0.025} />
                <div
                  sx={{
                    position: `absolute`,
                    zIndex: 1,
                    height: `24px`,
                    top: `auto`,
                    bottom: 0,
                  }}
                >
                  <GatsbyLogoContainered height="24px" />
                </div>
              </div>
              <Text as="p" mt={3} fontSize={1} color="grey.50">
                Logo at 24px height
              </Text>
            </ContentColumn>
          </Columns>
        </Section>

        <Section id="guidance">
          <SectionHeading>Guidance</SectionHeading>
          <Columns>
            <CopyColumn>
              <p>
                Please help us maintain the integrity of the Gatsby logo and
                promote the consistency of the brand by not misusing it. Be
                responsible, not reckless.{` `}
                <span role="img" aria-label="thank you">
                  🙏
                </span>
              </p>
              <p>
                If you find your needs are not covered by the logo and its
                recommended usage, please{` `}
                <a href="mailto:team@gatsbyjs.com">get in touch</a>.
              </p>
            </CopyColumn>
            <ContentColumn>
              <Flex
                sx={{
                  flexWrap: `wrap`,
                }}
              >
                {data.allGuidanceYaml.nodes.map((node, index) => (
                  <Guidance
                    image={node.image && node.image}
                    key={`logo-guidance-${index}`}
                  >
                    {node.description}
                  </Guidance>
                ))}
              </Flex>
            </ContentColumn>
          </Columns>
        </Section>

        <Section id="footnotes">
          <SectionHeading>Footnotes</SectionHeading>
          <Columns>
            <CopyColumn>
              <p>
                Originally created by Sacha Greif in late 2016, the Gatsby logo
                is a true open source community effort. We compiled some of the
                steps that lead to the current version of the logo:
              </p>
            </CopyColumn>
            <ContentColumn>
              <ul>
                {data.allFootnotesYaml.nodes.map((node, index) => (
                  <Text as="li" key={`logo-footnotes-${index}`} mb={4}>
                    {node.description}:<br />
                    <a
                      sx={{
                        color: `purple.50`,
                        textDecoration: `none`,
                      }}
                      href={node.href}
                      key={`logo-footnotes-${index}`}
                    >
                      {node.href.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ``)}
                    </a>
                  </Text>
                ))}
              </ul>
            </ContentColumn>
          </Columns>
        </Section>
      </GuidelinesLayout>
    </Layout>
  )
}

export default Logo

export const pageQuery = graphql`
  query logoGuideQuery {
    allFootnotesYaml {
      nodes {
        description
        href
      }
    }
    allGuidanceYaml {
      nodes {
        description
        image {
          childImageSharp {
            fluid(maxWidth: 380, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
